import React from "react";
import NavBar from "../Components/Shared/Navbar";
import AppBreadcrumb from "../Components/Shared/AppBreadcrumb";
import ContactForm from "../Components/Form/ContactForm";
import Footer from "../Components/Shared/Footer";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import WebHeading from "../Components/Shared/WebHeading";
import Images from "../Constants/Images";

function ContactUs() {
    const contactUsPage = [
        { label: "Home", href: "/", active: false },
        { label: "contact us", href: "/ContactUs", active: true }
    ];
    return (
        <React.Fragment>
            <NavBar />
            <AppBreadcrumb items={contactUsPage} title="contact us" />
            <section className="contact-us">
                <Container>
                    <Row className="row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
                        <Col>
                            <WebHeading
                                title="Have a question? Get a Free Consultation"
                                description="We're here to help! Reach out to us through any of the following methods, and our team will get back to you as soon as possible."
                            />
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <img src={Images.locationIcon} alt="location" />
                                    <div>
                                        <h5>Address</h5>
                                        <p>Ambience Creacions, Old Delhi-Gurgaon Road, Sector 22, Gurgaon</p>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <img src={Images.gmailIcon} alt="gmail" />
                                    <div>
                                        <h5>Email</h5>
                                        <a href="mailto:oxfordrealtors@gmail.com">Oxfordrealtors@gmail.com</a>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <img src={Images.callIcon} alt="call" />
                                    <div>
                                        <h5>Phone</h5>
                                        <a href="tel:9999637378">+91-9999-637-378</a>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col>
                            <ContactForm rowClass="row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </React.Fragment>
    )
}
export default ContactUs;