import React from "react";
import { Card, Col, Container, Row, CardBody } from "react-bootstrap";
import data from '../../Data/blog.json'
function BlogCard() {
    return (
        <section className="our-properties blog-page">
            <Container>
                <Row
                    className="row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 justify-content-center">
                    {data.map(item => (
                        <Col key={item.id} className="d-flex">
                            <Card>
                                <div className="propertie-card-img">
                                    <img src={item.image} className="card-img-top" alt={`${item.title} image`} />
                                    <span className="badge">{item.date}</span>
                                </div>
                                <Card.Body className="card-body">
                                    <Card.Title>
                                        <a href={item.link} className="stretched-link"> {item.title}</a>
                                    </Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    )
}
export default BlogCard;