import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { aboutUs } from '../../Constants/Images';
import { Link } from "react-router-dom";
import GlobalRoutes from "../../Constants/GlobalRoutes";
function CtaAction() {
    return (
        <section className="cta-home">
            <Container>
                <Row className="justify-content-center align-items-center g-4">
                    <Col xxl={5} xl={5} lg={6} md={8}>
                        <h2>Find Best Place For Leaving</h2>
                        <p>Find Best Place For Leaving</p>
                    </Col>
                    <Col xxl={2} xl={2} lg={4} md={4}>
                        <Link to={GlobalRoutes.publicRoutes.contactUs} className="btn btn-dark">get in touch</Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default CtaAction;