import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// import data from "../../Data/properties.json"
import { Link } from "react-router-dom"; // For routing with React Router
import GlobalRoutes from "../../Constants/GlobalRoutes";
import { getList } from '../../Api/globalFunction';
import Loader from "../Shared/Loader";
import ErrorModal from "../Shared/ErrorModal";
import { apiEndpoints } from '../../Api/apiEndpoints';

function PropertieCard() {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchItems = async () => {
            setLoading(true);
            try {
                const data = await getList(apiEndpoints.properties);
                if (data) {
                    setProperties(data);
                    setLoading(false);
                }
            } catch (err) {
                setError("Failed to fetch items");
            }
        };
        fetchItems();
    }, []);

    if (loading) {
        return <div><Loader isLoading={loading} /></div>
    }

    return (
        <div className="our-properties">
            <Row className="row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 justify-content-center">
                {properties.map((property, index) => (
                    <Col key={index} className="d-flex">
                        <Card>
                            <div className="propertie-card-img">
                                <img
                                    src={property.ImagePath || "default_image_url.jpg"} // Fallback for missing images
                                    className="card-img-top"
                                    alt={property.Name}
                                />
                                <span className="badge">
                                    {property.Price || "Price not available"}
                                </span>
                            </div>
                            <Card.Body className="card-body">
                                <Card.Title>
                                    <Link
                                        to={GlobalRoutes.propertiesdetails} // Correct dynamic link
                                        className="stretched-link"
                                    >
                                        {property.Name}
                                    </Link>
                                </Card.Title>
                                <div className="location">
                                    <i className='bx bxs-map-pin'></i>
                                    <span className="location-pin">{property.Location || "Unknown location"}</span>
                                    <span className={`badge bg-${property.Status === "Sale" ? "success" : "secondary"}`}>
                                        {property.Status || "N/A"}
                                    </span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                {/* {data.map(item => (
                    <Col key={item.id} className="d-flex">
                        <Card>
                            <div className="propertie-card-img">
                                <img src={item.image} className="card-img-top" alt={`${item.title} image`} />
                                <span className="badge text-bg-success">{item.price}</span>
                            </div>
                            <Card.Body className="card-body">
                                <Card.Title>
                                    <a href={item.link} className="stretched-link"> {item.title}</a>
                                </Card.Title>
                                <div className="location">
                                    <i className='bx bxs-map-pin'></i>
                                    <span className="location-pin">{item.location}</span>
                                    <span className="badge bg-success">
                                        {item.badge}
                                    </span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))} */}
            </Row>
            <ErrorModal
                show={error}
                message={error}
                onHide={() => setError(null)}
            />
        </div>
    );
}

export default PropertieCard;
