import React from "react";
import { Modal, Button } from "react-bootstrap";

const ErrorModal = ({ show, message, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="error-modal"
      centered
    >
      <Modal.Header closeButton style={{ backgroundColor: "#f8d7da", borderBottom: "1px solid #f5c6cb" }}>
        <Modal.Title id="error-modal" style={{ color: "#721c24" }}>
          Error
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ color: "#721c24", backgroundColor: "#f8d7da" }}>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#f8d7da" }}>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
