import React, { useState, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Components/Shared/Navbar";
import Footer from "../Components/Shared/Footer";
import ContactForm from "../Components/Form/ContactForm";

function PropertyDetails() {
    const [activeTab, setActiveTab] = useState("property-overview");
    // Handle scroll and set the active tab
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll(".tab-pane");
            let currentTab = "property-overview"; // Default active tab

            sections.forEach((section) => {
                const sectionTop = section.offsetTop - 100; // Adjust for sticky headers or padding
                const sectionBottom = sectionTop + section.offsetHeight;

                if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
                    currentTab = section.id;
                }
            });

            setActiveTab(currentTab);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    // sliderimg
    const sliserImages = [
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/205097/large/sostlhrkwkte.jpg",
    ];

    const carouselOptions = {
        loop: true,
        margin: 1,
        nav: true,
        dots: true,
        autoplay: true,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 3,
            },
        },
    };
    return (
        <React.Fragment>
            <NavBar />
            <section className="properties-header">
                <Container fluid>
                    <OwlCarousel className="owl-theme" {...carouselOptions}>
                        {sliserImages.map((image, index) => (
                            <div className="item" key={index}>
                                <img src={image} alt={`properties-img-${index}`} />
                            </div>
                        ))}
                    </OwlCarousel>
                </Container>
            </section>
            <section className="properties-details">
                <Container>
                    <Row className="g-4">
                        <Col xxl={8} xl={8} lg={8} md={12}>
                            <div className="propertie-heading">
                                <h5>Super Collection O Sector 44 Near Botanical Garden formerly Hotel Culture</h5>
                                <p>Plot Num H1, H Block, Near Saver City Apartment, Sector 44, Noida</p>
                                <ul className="properties-action">
                                    <li>
                                        <a href="tel:9650977920" className="btn btn-primary">
                                            <i className="bx bxs-phone-call bx-tada"></i> 9650977920
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:9845218941" className="btn btn-success">
                                            <i className="bx bxl-whatsapp-square"></i> WhatsApp
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:oxfordrealtors@gmail.com" className="btn btn-light">
                                            <i className="bx bxs-envelope"></i> oxfordrealtors@gmail.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="properties-details-tabs">
                                <ul className="nav nav-pills">
                                    <li className="nav-item">
                                        <a
                                            href="#property-overview"
                                            className={`nav-link ${activeTab === "property-overview" ? "active" : ""}`}
                                        >
                                            Property Overview
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#location"
                                            className={`nav-link ${activeTab === "location" ? "active" : ""}`}
                                        >
                                            Location
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#property-highlights"
                                            className={`nav-link ${activeTab === "property-highlights" ? "active" : ""}`}
                                        >
                                            Property Highlights
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#key-feature"
                                            className={`nav-link ${activeTab === "key-feature" ? "active" : ""}`}
                                        >
                                            Key Features
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#why-choose"
                                            className={`nav-link ${activeTab === "why-choose" ? "active" : ""}`}
                                        >
                                            Why Choose
                                        </a>
                                    </li>
                                </ul>
                                <div className="scrollspy">
                                    <div className="tab-pane" id="property-overview">
                                        <h5>Property Overview</h5>
                                        <p>
                                            Welcome to the epitome of elegance and class, nestled within the serene and secure
                                            enclave of Nirvana Country, Sector 50, Gurgaon.
                                        </p>
                                    </div>
                                    <div className="tab-pane" id="location">
                                        <h5>Location</h5>
                                        <p>
                                            Nirvana Country, Sector 50, Gurgaon. Nirvana Country is famous for its tranquil
                                            surroundings, lush green spaces, and top-class facilities.
                                        </p>
                                    </div>
                                    <div className="tab-pane" id="property-highlights">
                                        <h5>Property Highlights</h5>
                                        <p>
                                            Offering the epitome of luxurious residing, this beautiful 6BHK Duplex Villa spans
                                            360 rectangular yards, comprising the Ground, First, and Second floors.
                                        </p>
                                    </div>
                                    <div className="tab-pane" id="key-feature">
                                        <h5>Key Features:</h5>
                                        <ol>
                                            <li>Spacious and Elegant Interiors</li>
                                            <li>Ground Floor</li>
                                            <li>First Floor</li>
                                            <li>Second Floor</li>
                                            <li>Outdoor Spaces</li>
                                            <li>High-Level Security</li>
                                        </ol>
                                    </div>
                                    <div className="tab-pane" id="why-choose">
                                        <h5>Why Choose This Villa?</h5>
                                        <ul>
                                            <li>Luxurious Living</li>
                                            <li>Prime Location</li>
                                            <li>Spacious Accommodation</li>
                                            <li>Exclusive Amenities</li>
                                            <li>Investment Opportunity</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={12}>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default PropertyDetails;
