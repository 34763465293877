import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/css.css';
import './assets/css/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from './Routes/AppRoutes';
import { toast, ToastContainer } from "react-toastify";


function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
