const GlobalRoutes = {
    publicRoutes: {
        about: "/about-us",
        blogs: "/our-blogs",
        director: "/director",
        contactUs: "/contact-us",
        plots: "/plots",
        properties: "/properties",
        propertiesDetails: "/property/properties-details",
        AdminLogin: "/login"
    },
    adminRoutes: {
        adminLogin: "/admin/login",
        Dashboard: "/admin/dashboard",
    },
};

export default GlobalRoutes;

