import React from "react";

function WebHeading({ title, description, subheading }) {
    return (
        <div className="heading-section">
            <span className="subheading">{subheading}</span>
            <h2 className="heading-title">{title}</h2>
            <p className="heading-description">{description}</p>
        </div>
    );
}

export default WebHeading;
