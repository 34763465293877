import React from "react";
import HomeSlider from "../Components/Slider/Slider"
import NavBar from "../Components/Shared/Navbar";
import SearchBar from "../Components/Search/SearchBar";
import OxfordCategories from "../Components/Categories/OxfordCategories";
import PropertieCard from "../Components/Properties/PropertieCard";
import AboutDirector from "../Components/About/AboutDirector";
import CtaAction from "../Components/Cta/CtaAction";
import Footer from "../Components/Shared/Footer";
import { Container } from "react-bootstrap";
import WebHeading from "../Components/Shared/WebHeading";
import Testimonials from "../Components/Testimonials/Testimonials";

function Home() {
    return (
        <React.Fragment>
            <NavBar />
            <HomeSlider />
            <SearchBar />
            <OxfordCategories />
            <section className="bg-light py-5">
                <Container>
                    <div className="text-center">
                        <WebHeading
                            subheading="Our Properties"
                            title="Featured Properties"
                        />
                    </div>
                    <PropertieCard />
                </Container>
            </section>
            <AboutDirector />
            <CtaAction />
            <Testimonials />
            <Footer />
        </React.Fragment>
    );
}

export default Home;