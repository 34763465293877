import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function HomeSlider() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <section className='header-slider'>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <img src="https://qa.vcqru.com/oxfordrealtors/assets/img/slider-one.jpg" class="d-block w-100" alt="Slider" />
                    <Carousel.Caption>
                        <h1>Find Your Dream Home in Gurugram</h1>
                        <p>Open the doors to your dream home in Gurugram! Don't settle for less—let us help you find the
                            home that ignites your imagination and sparks a lifetime of happiness. Begin your journey to
                            homeownership with Oxford Realtors today.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="Second slide" /> */}
                    <img src="https://qa.vcqru.com/oxfordrealtors/assets/img/slider-one.jpg" class="d-block w-100" alt="Slider" />
                    <Carousel.Caption>
                        <h1>Builder Floor in Gurugram</h1>
                        <p>Luxury Builder Floors in Gurugram - Independent housing units built by renowned builders,
                            offer an unparalleled level of luxury and sophistication. With lavish interiors, high-end
                            finishes, and state-of-the-art amenities, these builder floors exemplify elegance and
                            grandeur.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="Third slide" /> */}
                    <img src="https://qa.vcqru.com/oxfordrealtors/assets/img/slider-one.jpg" class="d-block w-100" alt="Slider" />
                    <Carousel.Caption>
                        <h1>Ready to Move Property in Gurugram</h1>
                        <p>Find the perfect Ready to Move property in Gurugram. Experience a seamless transition into
                            your dream home, free from construction delays and uncertainties. Enjoy the vibrant
                            lifestyle and premium infrastructure of Gurugram, now just a step away.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    );
}

export default HomeSlider;