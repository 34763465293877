import React from 'react';
import { DNA } from 'react-loader-spinner'; 

const Loader = ({ isLoading, color, size }) => {
  if (!isLoading) return null;

  return (
    <div style={styles.overlay}>
      <DNA 
        height={size} 
        width={size} 
        color={color} 
        visible={isLoading} 
        ariaLabel="loading-indicator"
      />
    </div>
  );
};

Loader.defaultProps = {
  color: '#00BFFF', 
  size: 80,      
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
};

export default Loader;
