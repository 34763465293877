import React from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import Images from "../../Constants/Images";
import { Link } from 'react-router-dom';
import GlobalRoutes from '../../Constants/GlobalRoutes';
import { useState, useEffect } from 'react';

function NavBar() {
    // Mock user role (replace with real authentication logic)
    const [isAdmin, setIsAdmin] = useState(false);

    // Example logic for setting admin (replace with actual user role check)
    useEffect(() => {
        const userRole = localStorage.getItem("userRole"); // Example: Fetch from local storage
        if (userRole === "admin") {
            setIsAdmin(true);
        }
    }, []);

    return (
        <div className='navbar-layout'>
            <Container>
                <Navbar expand="lg">
                    <Container fluid>
                        <Link className='navbar-brand' to="/">
                            <img src={Images.logo} alt="logo" />
                        </Link>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="ms-auto mb-2 my-lg-0">
                                <Link className='nav-link' to="/">Home</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.properties}>Properties</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.director}>Director</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.about}>About</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.blogs}>Blog</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.contactUs}>Contact</Link>
                                {isAdmin && (
                                    <React.Fragment>
                                        <Link className='nav-link' to={GlobalRoutes.adminRoutes.Dashboard}>Admin Dashboard</Link>
                                        <Link className='nav-link' to={GlobalRoutes.adminRoutes.manageUsers}>Manage Users</Link>
                                        <Link className='nav-link' to={GlobalRoutes.adminRoutes.settings}>Settings</Link>
                                    </React.Fragment>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </div>
    );
}

export default NavBar;
