import React from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function AppBreadcrumb({ items, title }) {
    return (
        <section className="app-breadcrumb">
            <Container>
                <Breadcrumb>
                    {items.map((item, index) =>
                        item.active ? (
                            <li key={index} className="breadcrumb-item active" aria-current="page">
                                {item.label}
                            </li>
                        ) : (
                            <li key={index} className="breadcrumb-item">
                                <Link to={item.href}>{item.label}</Link>
                            </li>
                        )
                    )}
                </Breadcrumb>
                <h1>{title}</h1>
            </Container>
        </section>
    );
}

export default AppBreadcrumb;
