import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
function SearchBar() {
    return (
        <section class="search-bar">
            <Container>
                <Form action="">
                    <Row className="row row-cols-xxl-5 row-cols-xl-5 row-cols-lg-5 row-cols-md-2 row-cols-1 g-0 justify-content-center">
                        <Col className="d-flex">
                            <Form.Group className="form-group">
                                <label for="EnterKeyword" class="form-label">Enter Keyword</label>
                                <input type="text" name="" id="EnterKeyword" className="form-control" placeholder="Enter Keyword" />
                            </Form.Group>
                        </Col>
                        <Col className="d-flex">
                            <Form.Group className="form-group">
                                <label for="ListingType" className="form-label">Listing Type</label>
                                <select className="form-select" id="ListingType">
                                    <option selected>all</option>
                                    <option value="1">All</option>
                                    <option value="2">For Sale</option>
                                    <option value="3">For Rent</option>
                                    <option value="3">For Lease</option>
                                </select>
                            </Form.Group>
                        </Col>
                        <Col className="d-flex">
                            <Form.Group className="form-group">
                                <label for="PropertyType" className="form-label">Property Type</label>
                                <select className="form-select" id="PropertyType">
                                    <option value="">Any</option>
                                    <option value="Plots">Plots</option>
                                    <option value="Office">Office</option>
                                    <option value="Villas">Villas</option>
                                    <option value="4BHK">4BHK</option>
                                    <option value="3BHK">3BHK</option>
                                    <option value="Builder Floor">Builder Floor</option>
                                    <option value="House">House</option>
                                </select>
                            </Form.Group>
                        </Col>
                        <Col className="d-flex">
                            <Form.Group className="form-group">
                                <label for="Location" className="form-label">Location</label>
                                <select className="form-select" id="Location">
                                    <option selected>Select Location</option>
                                    <option value="Haryana">Haryana</option>
                                </select>
                            </Form.Group>
                        </Col>
                        <Col className="d-flex">
                            <Form.Group className="form-group">
                                <button type="submit" className="btn btn-success">SEARCH</button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </section >
    )
}
export default SearchBar;