import React from "react";
import PropertieCard from '../Components/Properties/PropertieCard'
import AppBreadcrumb from "../Components/Shared/AppBreadcrumb";
import NavBar from "../Components/Shared/Navbar";
import SearchBar from "../Components/Search/SearchBar";
import Footer from "../Components/Shared/Footer";
import { Container } from "react-bootstrap";
function Plotes() {
    const plotesPage = [
        { label: "Home", href: "/", active: false },
        { label: "Plotes", href: "/Plotes", active: true }
    ];
    return (
        <React.Fragment>
            <NavBar />
            <AppBreadcrumb items={plotesPage} title="Plotes" />
            <SearchBar />
            <section className="py-5 bg-light">
                <Container>
                    <PropertieCard />
                </Container>
            </section>
            <Footer />
        </React.Fragment>
    )
}
export default Plotes;