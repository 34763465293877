import React from "react";
import NavBar from "../Components/Shared/Navbar";
import AppBreadcrumb from "../Components/Shared/AppBreadcrumb";
import BlogCard from "../Components/Blog/BlogCards";
import Footer from "../Components/Shared/Footer";
function Blog() {
    const blogPage = [
        { label: "Home", href: "/", active: false },
        { label: "blog", href: "/Blog", active: true }
    ];
    return (
        <React.Fragment>
            <NavBar />
            <AppBreadcrumb items={blogPage} title="blog" />
            <BlogCard />
            <Footer />
        </React.Fragment>
    )
}
export default Blog;