import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container } from "react-bootstrap";
import WebHeading from "../Shared/WebHeading";

const testimonials = [
    {
        text: "Excellent service and very experienced team. He helped me find good property in least amount in my preferred location.",
        name: "Rupesh Rai",
    },
    {
        text: "My experience with Oxford Realtors was really good. The team is great and efficient. I would like to say All the best to the entire Oxford",
        name: "Vivek",
    },
    {
        text: "I have got genuine and very fast response from the site. I am very pleased with the service provided by Oxford Realtors.",
        name: "Shubham Nair",
    },
    {
        text: "I was looking for a suitable office space for my startup in Gurugram, and Oxford Realtors came to my rescue.",
        name: "Kishore Pandit",
    },
    {
        text: "Oxford Realtors exceeded my expectations in every aspect of my home purchase. Their team was incredibly professional.",
        name: "Ajith Kumar",
    },
];

const Testimonials = () => {
    return (
        <div className="testimonial">
            <Container>
                <WebHeading
                    subheading="Testimonial"
                    title="Clients We Help"
                />
                <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    nav
                    responsive={{
                        0: { items: 1 },
                        600: { items: 2 },
                        1000: { items: 3 },
                    }}
                >
                    {testimonials.map((testimonial, index) => (
                        <div className="item" key={index}>
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="icon mb-3">
                                        <i className="bx bxs-quote-alt-left"></i>
                                    </div>
                                    <p className="card-text">{testimonial.text}</p>
                                    <h5 className="card-title">{testimonial.name}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </Container>
        </div>
    );
};

export default Testimonials;
