import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, CardText, CardTitle, Col, Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikControl from "../../Formik/FormikControl";
import Images from "../../Constants/Images";
import GlobalRoutes from "../../Constants/GlobalRoutes";

function LoginForm() {
    const navigate = useNavigate();

    const staticCredentials = {
        email: "akash@vcqru.com",
        password: "123",
    };

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required"),
    });

    const initialValues = {
        email: "akash@vcqru.com",
        password: "123",
    };

    const onSubmit = (values, { setSubmitting }) => {
        if (
            values.email === staticCredentials.email &&
            values.password === staticCredentials.password
        ) {
            toast.success("Login successful!");
            navigate(GlobalRoutes.adminRoutes.Dashboard);
        } else {
            toast.error("Invalid email or password");
        }
        setSubmitting(false);
    };

    return (
        <section className="admin-login">
            <Container>
                <Row>
                    <Col lg={5} className="mx-auto">
                        <Card>
                            <CardBody>
                                <div className="admin-logo">
                                    <img src={Images.logo} alt="Admin Logo" />
                                </div>
                                <CardTitle>Login</CardTitle>
                                <CardText>
                                    Log in with your data that you entered during registration
                                </CardText>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <Row className="g-3 row-cols-1">
                                                <FormikControl
                                                    control="textInput"
                                                    label="Email*"
                                                    name="email"
                                                    type="email"
                                                    className="col"
                                                />
                                                <FormikControl
                                                    control="textInput"
                                                    label="Password*"
                                                    name="password"
                                                    type="password"
                                                    className="col"
                                                />
                                                <Col>
                                                    <div className="form-group">
                                                        <Button
                                                            variant="success"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting
                                                                ? "Logging in..."
                                                                : "Login"}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LoginForm;
