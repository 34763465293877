import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Blog from "../Pages/Blog";
import ContactUs from "../Pages/ContactUs";
import Director from "../Pages/Director";
import Properties from "../Pages/Properties";
import Plotes from "../Pages/Plots";
import PropertyDetails from "../Pages/PropertyDetails";
import GlobalRoutes from "../Constants/GlobalRoutes.js";
import AdminLogin from "../Pages/Admin/Login";
import Dashboard from "../Pages/Admin/Dashboard";
import LoginForm from "../Components/Form/LoginForm.jsx";

export default function AppRoutes() {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [logedInUser, setLogedInUser] = useState("userRole");

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userRole = localStorage.getItem("userRole"); // Admin or User

        if (token && userRole) {
            setIsAuthenticated(true);
            setLogedInUser(userRole);
        }
    }, []);
    const userRoutes = [
        {
            path: "/",
            children: [
                { index: true, element: <Home /> },
                { path: GlobalRoutes.publicRoutes.about, element: <About /> },
                { path: GlobalRoutes.publicRoutes.director, element: <Director /> },
                { path: GlobalRoutes.publicRoutes.blogs, element: <Blog /> },
                { path: GlobalRoutes.publicRoutes.contactUs, element: <ContactUs /> },
                { path: GlobalRoutes.publicRoutes.plots, element: <Properties /> },
                { path: GlobalRoutes.publicRoutes.properties, element: <Plotes /> },
                { path: GlobalRoutes.publicRoutes.propertiesDetails, element: <PropertyDetails /> },
                { path: GlobalRoutes.publicRoutes.AdminLogin, element: <AdminLogin /> },
            ],
        },
    ];
    const authRoutes = [
        {
            path: "/login",
            children: [
                { index: true, element: <LoginForm /> }
            ]
        }
    ];
    const adminRoutes = [
        {
            path: "/admin",
            children: [
                { index: true, element: <Home /> },
                { path: GlobalRoutes.adminRoutes.Dashboard, element: <Dashboard /> },
            ],
        },
    ];
    const routes = !isAuthenticated ? authRoutes : logedInUser === "Admin" ? adminRoutes : userRoutes;

    return useRoutes(routes);
}
