import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import data from '../../Data/categories.json';
import WebHeading from "../Shared/WebHeading";
function OxfordCategories() {
    return (
        <section class="oxfor-categories">
            <Container>
                <WebHeading
                    subheading="Oxford Categories"
                    title="Explore Properties by Category"
                    description="Categories that make your dreams home come true. You can choose specific properties as per your requirement"
                />
                <Row>
                    <Col xxl={8} xl={8} className="mx-auto">
                        <Row class="row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-2 g-3">
                            {data.map(item => (
                                <Col key={item.id}>
                                    <a href={item.href}>
                                        <i className={`bx ${item.icon}`}></i>
                                        {item.title}
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default OxfordCategories;