import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Images from '../../Constants/Images';
import { Link } from "react-router-dom";
import WebHeading from "../Shared/WebHeading";
import GlobalRoutes from "../../Constants/GlobalRoutes";
function AboutDirector() {
    return (
        <section class="about-director">
            <Container>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={12} className="d-flex">
                        <img src="https://qa.vcqru.com/oxfordrealtors/assets/img/about.jpg" alt="about" />
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={12}>
                        <WebHeading
                            subheading="About the Director"
                            title="Vikas Arora (MBA, M.Com)"
                            description="Mr. Vikas Arora has 15 years + experience in Real Estate Sales & Finance Oxford Realtors is his own Professional Company, deals in sale purchase leasing of properties in Gurgaon, Delhi NCR. Deals in Hotels, Hospitals, Schools, Offices & Housing All Over India. Mr. Vikas Arora Is Also an Expert in Real Estate & provides PROPERTY SOLUTION’S. Check out the YouTube & Instagram Page"
                        />
                        <Link to={GlobalRoutes.publicRoutes.director} className="btn btn-success">Learn More <i class="bx bx-arrow-back bx-rotate-180"></i></Link>
                        <div class="about-counter">
                            <Row class="row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1 g-3">
                                <Col class="col">
                                    <h2>15+</h2>
                                    <p>Years of Experienced</p>
                                </Col>
                                <Col class="col">
                                    <h2>500+</h2>
                                    <p>Total Properties</p>
                                </Col>
                            </Row>
                        </div>
                        <img src={Images.aboutImg1} alt="about" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default AboutDirector;